// ! Moengage page visit event attributes (1001001)
const pageVisitAttributes = (kingdom, genus, variant = "") => {
  return {
    event_id: "1001001",
    event_type: "View",
    funnel_stage: "Consideration",
    event_category: "Browsing",
    feature_set: "Base",
    event_priority: "High",
    kingdom: kingdom,
    phylum: "",
    class: "",
    order: "Homepage",
    family: "1001001",
    genus: genus,
    species: "",
    sub_c_1: variant,
    sub_c_2: "",
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};

// ! Moengage footer event attributes (1001010)
const footertAttributes = (kingdom, genus, destUrl = "") => {
  return {
    event_id: "1001010",
    event_type: "Click",
    funnel_stage: "Consideration",
    event_category: "Browsing",
    feature_set: "Footer",
    event_priority: "Low",
    kingdom: kingdom,
    phylum: "",
    class: destUrl,
    order: "Homepage",
    family: "1001010",
    genus: 0,
    species: "",
    sub_c_1: "",
    sub_c_2: "",
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};

// ! Moengage age filter event attributes (1001024)
const ageFilterAttributes = (kingdom, filter) => {
  return {
    event_id: "1001024",
    event_type: "Click",
    funnel_stage: "Consideration",
    event_category: "Browsing",
    feature_set: "Base",
    event_priority: "High",
    kingdom: kingdom,
    phylum: filter.join("-"),
    class: "",
    order: "Homepage",
    family: 207,
    genus: "2",
    species: "",
    sub_c_1: "",
    sub_c_2: "",
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! Moengage add to cart event attributes (1001018)
const addToCartAttributes = (
  courseCardId,
  courseCardName,
  coursePrice,
  isCombo = 0
) => {
  return {
    event_id: "1001018",
    event_type: "Click",
    funnel_stage: "Conversion",
    event_category: "Cart",
    feature_set: "Base",
    event_priority: "High",
    kingdom: courseCardId,
    phylum: courseCardName,
    class: "Button state - clicked",
    order: coursePrice,
    family: 220,
    genus: "3",
    species: "",
    sub_c_1: "",
    sub_c_2: isCombo,
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! Moengage click to homepage event attributes (1001002)
const clickToHomepageAttributes = () => {
  return {
    event_id: "1001002",
    event_type: "Click",
    funnel_stage: "Consideration",
    event_category: "Browsing",
    feature_set: "Base",
    event_priority: "High",
    kingdom: "",
    phylum: "",
    class: "/",
    order: "",
    family: "1001002",
    genus: "",
    species: "",
    sub_c_1: "",
    sub_c_2: "",
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! Moengage click to homepage event attributes (1001003)
const mainMenuClickAttributes = (
  itemID,
  itemTitle,
  destinationUrl,
  buttonState,
  leadScore,
  destinationPageId
) => {
  return {
    event_id: "1001003",
    event_type: "Click",
    funnel_stage: "Consideration",
    event_category: "Browsing",
    feature_set: "Base",
    event_priority: "High",
    kingdom: itemID,
    phylum: itemTitle,
    class: destinationUrl,
    order: buttonState,
    family: 200,
    genus: leadScore,
    species: destinationPageId,
    sub_c_1: "",
    sub_c_2: "",
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! Moengage category filter event attributes (1001031)
const categoryFilterAttributes = (categoryId, categoryTitle) => {
  return {
    event_id: "1001031",
    event_type: "Click",
    funnel_stage: "Consideration",
    event_category: "Browsing",
    feature_set: "Base",
    event_priority: "High",
    kingdom: categoryId,
    phylum: categoryTitle,
    class: "",
    order: "",
    family: 208,
    genus: "2",
    species: "",
    sub_c_1: "",
    sub_c_2: "",
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};

// ! Moengage login to buy event attributes (1001103)
const BuyNowAttributes = (
  courseId,
  courseTitle,
  sectionName,
  totalPrice,
  discountPrice,
  combo,
  location
) => {
  return {
    event_id: "1001103",
    event_type: "Click",
    funnel_stage: "Action",
    event_category: "Cart",
    feature_set: "Base",
    event_priority: "High",
    kingdom: courseId,
    phylum: courseTitle,
    class: sectionName,
    order: totalPrice,
    family: "",
    genus: 3,
    species: "",
    sub_c_1: discountPrice,
    sub_c_2: combo,
    sub_c_3: location,
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};

// ! Moengage login to buy event attributes (1001104)
const loginToBuyAttributes = (
  courseId,
  courseTitle,
  mobileNumber,
  order = ""
) => {
  return {
    event_id: "1001104",
    event_type: "Click",
    funnel_stage: "Action",
    event_category: "Register",
    feature_set: "Base",
    event_priority: "High",
    kingdom: courseId,
    phylum: courseTitle,
    class: mobileNumber,
    order: order,
    family: "",
    genus: "",
    species: "",
    sub_c_1: "",
    sub_c_2: "",
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};

// ! Moengage login to buy event attributes (1001105)
const rescheduleAttributes = (
  childAge,
  classDate,
  courseTitle,
  courseId,
  timeOfDateOfClass,
  timeFromClassInHours
) => {
  return {
    event_id: "1001105",
    event_type: "Click",
    funnel_stage: "Trial",
    event_category: "Book",
    feature_set: "Trial",
    event_priority: "High",
    kingdom: courseId,
    phylum: courseTitle,
    class: "",
    order: childAge,
    family: "",
    genus: "",
    species: classDate,
    sub_c_1: timeOfDateOfClass,
    sub_c_2: timeFromClassInHours,
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};

// ! Moengage search execution event attributes (1001012)
const searchExecuteAttributes = (
  searchTerm,
  returnedItemCount,
  result,
  leadScore,
  searchType
) => {
  return {
    event_id: "1001012",
    event_type: "Platform",
    funnel_stage: "Action",
    event_category: "Search",
    feature_set: "Search",
    event_priority: "High",
    kingdom: searchTerm,
    phylum: returnedItemCount,
    class: result,
    order: "",
    family: 214,
    genus: leadScore,
    species: "",
    sub_c_1: searchType,
    sub_c_2: "",
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! Moengage checkout event attributes (1001038)
const checkoutAttributes = (
  totalQuantity,
  cartImageStyle,
  locationOfTrigger,
  cartTotal,
  leadScore,
  promoCode,
  discountVisible
) => {
  return {
    event_id: "1001038",
    event_type: "Click",
    funnel_stage: "Action",
    event_category: "Cart",
    feature_set: "Checkout",
    event_priority: "High",
    kingdom: totalQuantity,
    phylum: cartImageStyle,
    class: locationOfTrigger,
    order: cartTotal,
    family: 216,
    genus: leadScore,
    species: promoCode,
    sub_c_1: discountVisible,
    sub_c_2: "",
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! Moengage book trial page view event attributes (1001014)
const bookTrialViewAttributes = (referrerCode = "") => {
  return {
    event_id: "1001014",
    event_type: "View",
    funnel_stage: "Trial",
    event_category: "Book",
    feature_set: "Trial",
    event_priority: "High",
    kingdom: "",
    phylum: "",
    class: "",
    order: "",
    family: "1001014",
    genus: "",
    species: "",
    sub_c_1: "",
    sub_c_2: "",
    sub_c_3: referrerCode,
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! Moengage book trial click view event attributes (1001015)
const bookTrialClickAttributes = (
  buttonTitle,
  destinationUrl,
  trialOrWorkshop,
  location,
  clickSource
) => {
  return {
    event_id: "1001015",
    event_type: "Click",
    funnel_stage: "Trial",
    event_category: "Book",
    feature_set: "Trial",
    event_priority: "High",
    kingdom: 1,
    phylum: buttonTitle,
    class: destinationUrl,
    order: trialOrWorkshop,
    family: 230,
    genus: 3,
    species: 3,
    sub_c_1: location,
    sub_c_2: clickSource,
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! Moengage book trial field filled event attributes (1001017)
const bookTrialFieldFilledAttributes = (fieldId, fieldTitle, fieldValue) => {
  return {
    event_id: "1001017",
    event_type: "Platform",
    funnel_stage: "Trial",
    event_category: "Book",
    feature_set: "Trial",
    event_priority: "High",
    kingdom: fieldId,
    phylum: fieldTitle,
    class: fieldValue,
    order: "",
    family: 217,
    genus: ".5",
    species: "",
    sub_c_1: "",
    sub_c_2: "",
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! Moengage book trial submit event attributes (1001026)
const bookTrialSubmitAttributes = (
  stageId,
  stageTitle,
  dataValidated,
  nextStageId,
  sourceLocation = ""
) => {
  return {
    event_id: "1001026",
    event_type: "Click",
    funnel_stage: "Trial",
    event_category: "Book",
    feature_set: "Trial",
    event_priority: "High",
    kingdom: stageId,
    phylum: stageTitle,
    class: dataValidated,
    order: "",
    family: 218,
    genus: "1",
    species: nextStageId,
    sub_c_1: "",
    sub_c_2: "",
    app_version: "0.1.13",
    a_b_variant: "a",
    source: sourceLocation,
  };
};
// ! Moengage book trial success event attributes (1001037)
const bookTrialSuccessAttributes = (
  stageId,
  stageTitle,
  dataValidated,
  nextStageId,
  leadScore,
  numberOfItemsSelected = 0,
  buttonTitle,
  subC5 = ""
) => {
  return {
    event_id: "1001037",
    event_type: "System",
    funnel_stage: "Trial",
    event_category: "Book",
    feature_set: "Trial",
    event_priority: "High",
    kingdom: stageId,
    phylum: stageTitle,
    class: dataValidated,
    order: buttonTitle,
    family: 219,
    genus: leadScore,
    species: nextStageId,
    sub_c_1: "",
    sub_c_2: numberOfItemsSelected,
    sub_c_5: subC5,
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! Moengage invoke payment event attributes (1001043)
const invokePaymentAttributes = (
  orderId,
  totalCartQuantity,
  billingDetailsString,
  totalCartPrice,
  currency,
  cartTotalWithoutDiscount,
  promoCode = ""
) => {
  return {
    event_id: "1001043",
    event_type: "Click",
    funnel_stage: "Action",
    event_category: "Cart",
    feature_set: "Checkout",
    event_priority: "High",
    kingdom: orderId,
    phylum: totalCartQuantity,
    class: billingDetailsString,
    order: totalCartPrice,
    family: 224,
    genus: "8",
    species: promoCode,
    sub_c_1: cartTotalWithoutDiscount,
    sub_c_2: currency,
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! Moengage payment status event attributes (1001044)
const paymentStatusAttributes = (
  orderId,
  totalCartQuantity,
  status,
  totalCartPrice,
  genus,
  currency,
  cartTotalWithoutDiscount,
  promoCode = ""
) => {
  return {
    event_id: "1001044",
    event_type: "Client",
    funnel_stage: "Conversion",
    event_category: "Cart",
    feature_set: "Checkout",
    event_priority: "High",
    kingdom: orderId,
    phylum: totalCartQuantity,
    class: status,
    order: totalCartPrice,
    family: 225,
    genus: genus,
    species: promoCode,
    sub_c_1: cartTotalWithoutDiscount,
    sub_c_2: currency,
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! Moengage request callback event attributes (1001033)
const requestCallbackAttributes = (
  nameFilled,
  mobileFilled,
  location,
  countryCode
) => {
  return {
    event_id: "1001033",
    event_type: "Click",
    funnel_stage: "Action",
    event_category: "Request",
    feature_set: "Parent",
    event_priority: "High",
    kingdom: nameFilled,
    phylum: mobileFilled,
    class: location,
    order: countryCode,
    family: 204,
    genus: ".5",
    species: "",
    sub_c_1: "",
    sub_c_2: "",
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! Moengage connect with us event attributes (1001034)
const connectWithUsAttributes = (
  linkedinDetailsFilled,
  location,
  linkedinUrl
) => {
  return {
    event_id: "1001034",
    event_type: "Click",
    funnel_stage: "Action",
    event_category: "Request",
    feature_set: "Teacher",
    event_priority: "High",
    kingdom: linkedinDetailsFilled,
    phylum: "",
    class: location,
    order: "",
    family: "1001034",
    genus: ".5",
    species: "",
    sub_c_1: linkedinUrl,
    sub_c_2: "",
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! Moengage join class event attributes (1001045)
const joinClassAttributes = (
  classId,
  courseId,
  classType,
  teacherPresent,
  leadScore,
  classDate,
  classTiming,
  sessionNo,
  timeSinceClassStart
) => {
  return {
    event_id: "1001045",
    event_type: "Click",
    funnel_stage: "Consumption",
    event_category: "Class",
    feature_set: "Base",
    event_priority: "High",
    kingdom: classId,
    phylum: courseId,
    class: classType,
    order: teacherPresent,
    family: "1001045",
    genus: leadScore,
    species: classDate,
    sub_c_1: classTiming,
    sub_c_2: sessionNo,
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! Moengage leave class event attributes (1001046)
const leaveClassAttributes = (
  classId,
  courseId,
  classType,
  teacherPresent,
  leadScore,
  classDate,
  classTiming,
  sessionNo,
  timeSinceClassStart
) => {
  return {
    event_id: "1001046",
    event_type: "Click",
    funnel_stage: "Consumption",
    event_category: "Class",
    feature_set: "Base",
    event_priority: "High",
    kingdom: classId,
    phylum: courseId,
    class: classType,
    order: teacherPresent,
    family: "1001046",
    genus: leadScore,
    species: classDate,
    sub_c_1: classTiming,
    sub_c_2: sessionNo,
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! Moengage button click event attributes (1001005)
const buttonClickAttributes = (
  buttonId,
  buttonTitle,
  destinationUrl,
  buttonTypeId,
  leadScore,
  destinationPageId,
  location
) => {
  return {
    event_id: "1001005",
    event_type: "Click",
    funnel_stage: "Consideration",
    event_category: "Browsing",
    feature_set: "Base",
    event_priority: "High",
    kingdom: buttonId,
    phylum: buttonTitle,
    class: destinationUrl,
    order: buttonTypeId,
    family: "1001005",
    genus: leadScore,
    species: destinationPageId,
    sub_c_1: location,
    sub_c_2: "",
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! Moengage card click event attributes (1001025)
const cardClickAttributes = (
  cardId,
  cardTitle,
  cardType,
  destinationPageId,
  age,
  cardFormatId
) => {
  return {
    event_id: "1001025",
    event_type: "Click",
    funnel_stage: "Consideration",
    event_category: "Browsing",
    feature_set: "Base",
    event_priority: "High",
    kingdom: cardId,
    phylum: cardTitle,
    class: "",
    order: cardType,
    family: 208,
    genus: 2,
    species: destinationPageId,
    sub_c_1: age,
    sub_c_2: cardFormatId,
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! Moengage add coupon event attributes (1001019)
const addCouponAttributes = (
  couponCode,
  numberOfItems,
  cartTotal,
  currency
) => {
  return {
    event_id: "1001019",
    event_type: "Click",
    funnel_stage: "Conversion",
    event_category: "Cart",
    feature_set: "Checkout",
    event_priority: "High",
    kingdom: couponCode,
    phylum: numberOfItems,
    class: "",
    order: cartTotal,
    family: 221,
    genus: 2,
    species: "",
    sub_c_1: currency,
    sub_c_2: "",
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! Moengage add coupon event attributes (1001020)
const addCouponSuccessAttributes = (
  couponCode,
  numberOfItems,
  status,
  cartTotal,
  finalPrice,
  currency
) => {
  return {
    event_id: "1001020",
    event_type: "Click",
    funnel_stage: "Conversion",
    event_category: "Cart",
    feature_set: "Checkout",
    event_priority: "High",
    kingdom: couponCode,
    phylum: numberOfItems,
    class: status,
    order: cartTotal,
    family: 222,
    genus: 2,
    species: finalPrice,
    sub_c_1: currency,
    sub_c_2: "",
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! Moengage course selection event attributes (1001039)
const courseSelectionAttributes = (
  courseId,
  courseTitle,
  coursePosition,
  childAge,
  referrersCourse,
  expandedState,
  dateOfClass = ""
) => {
  return {
    event_id: "1001039",
    event_type: "Click",
    funnel_stage: "Trial",
    event_category: "Book",
    feature_set: "Trial",
    event_priority: "Medium",
    kingdom: courseId,
    phylum: courseTitle,
    class: coursePosition,
    order: childAge,
    family: dateOfClass,
    genus: "",
    species: "",
    sub_c_1: referrersCourse,
    sub_c_2: expandedState,
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! Moengage slot selection event attributes (1001040)
const slotSelectionAttributes = (
  courseId,
  timeOfSlot,
  daysFromToday,
  childAge,
  dayOfWeek,
  referrersCourse,
  isRecommended
) => {
  return {
    event_id: "1001040",
    event_type: "Click",
    funnel_stage: "Trial",
    event_category: "Book",
    feature_set: "Trial",
    event_priority: "Medium",
    kingdom: courseId,
    phylum: timeOfSlot,
    class: daysFromToday,
    order: childAge,
    family: "1001040",
    genus: "",
    species: dayOfWeek,
    sub_c_1: referrersCourse,
    sub_c_2: "",
    sub_c_4: isRecommended,
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! Moengage request custom slot event attributes (1001041)
const requestCustomSlotAttributes = (
  courseId,
  numberOfSlotsAvailable,
  numberOfDaysViewed,
  childAge
) => {
  return {
    event_id: "1001041",
    event_type: "Click",
    funnel_stage: "Trial",
    event_category: "Book",
    feature_set: "Trial",
    event_priority: "Medium",
    kingdom: courseId,
    phylum: numberOfSlotsAvailable,
    class: numberOfDaysViewed,
    order: childAge,
    family: "1001041",
    genus: "",
    species: "",
    sub_c_1: "",
    sub_c_2: "",
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! Moengage enter OTP event attributes (1001047)
const enterOTPAttributes = (fieldId, fieldTitle, flowId, OTPType) => {
  return {
    event_id: "1001047",
    event_type: "Click",
    funnel_stage: "Action",
    event_category: "Register",
    feature_set: "Base",
    event_priority: "High",
    kingdom: fieldId,
    phylum: fieldTitle,
    class: "",
    order: flowId,
    family: "1001047",
    genus: "",
    species: OTPType,
    sub_c_1: "",
    sub_c_2: "",
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! Moengage OTP Status event attributes (1001048)
const OTPStatusAttributes = (status, genusStatus, OTPType) => {
  return {
    event_id: "1001048",
    event_type: "System",
    funnel_stage: "Action",
    event_category: "Register",
    feature_set: "Base",
    event_priority: "High",
    kingdom: "",
    phylum: "",
    class: status,
    order: "",
    family: "1001048",
    genus: genusStatus,
    species: OTPType,
    sub_c_1: "",
    sub_c_2: "",
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! Moengage request OTP event attributes (1001049)
const requestOTPAttributes = (flowId, OTPType) => {
  return {
    event_id: "1001049",
    event_type: "Click",
    funnel_stage: "Action",
    event_category: "Register",
    feature_set: "Base",
    event_priority: "High",
    kingdom: "",
    phylum: "",
    class: "",
    order: flowId,
    family: "1001049",
    genus: "",
    species: OTPType,
    sub_c_1: "",
    sub_c_2: "",
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! Moengage dismiss OTP event attributes (1001050)
const dismissOTPAttributes = (flowId, OTPType) => {
  return {
    event_id: "1001050",
    event_type: "Click",
    funnel_stage: "Action",
    event_category: "Register",
    feature_set: "Base",
    event_priority: "High",
    kingdom: "",
    phylum: "",
    class: "",
    order: flowId,
    family: "1001050",
    genus: "",
    species: OTPType,
    sub_c_1: "",
    sub_c_2: "",
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! Moengage resend OTP event attributes (1001051)
const resendOTPAttributes = (flowId, OTPType) => {
  return {
    event_id: "1001051",
    event_type: "Click",
    funnel_stage: "Action",
    event_category: "Register",
    feature_set: "Base",
    event_priority: "High",
    kingdom: "",
    phylum: "",
    class: "",
    order: flowId,
    family: "1001051",
    genus: "",
    species: OTPType,
    sub_c_1: "",
    sub_c_2: "",
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! Moengage login event attributes (1001052)
const loginAttributes = (loginType, flowId) => {
  return {
    event_id: "1001052",
    event_type: "Click",
    funnel_stage: "Action",
    event_category: "Register",
    feature_set: "Base",
    event_priority: "High",
    kingdom: loginType,
    phylum: "",
    class: "",
    order: flowId,
    family: "1001052",
    genus: "",
    species: "",
    sub_c_1: "",
    sub_c_2: "",
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! Moengage login Field Fill event attributes (1001053)
const loginFieldFillAttributes = (
  fieldId,
  fieldTitle,
  defaultFieldValue,
  flowId
) => {
  return {
    event_id: "1001053",
    event_type: "Click",
    funnel_stage: "Action",
    event_category: "Register",
    feature_set: "Base",
    event_priority: "High",
    kingdom: fieldId,
    phylum: fieldTitle,
    class: defaultFieldValue,
    order: flowId,
    family: "1001053",
    genus: "",
    species: "",
    sub_c_1: "",
    sub_c_2: "",
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! Moengage login Field Filled event attributes (1001054)
const loginFieldFilledAttributes = (fieldId, fieldTitle, flowId) => {
  return {
    event_id: "1001054",
    event_type: "Click",
    funnel_stage: "Action",
    event_category: "Register",
    feature_set: "Base",
    event_priority: "High",
    kingdom: fieldId,
    phylum: fieldTitle,
    class: "",
    order: flowId,
    family: "1001054",
    genus: 0.5,
    species: "",
    sub_c_1: "",
    sub_c_2: "",
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! Moengage select_batch_modal event attributes (1001057)
const selectBatchModalAttributes = (
  batchId,
  courseId,
  countryCode,
  startDate,
  numberOfClasses,
  frequency
) => {
  return {
    event_id: "1001057",
    event_type: "Click",
    funnel_stage: "Action",
    event_category: "Cart",
    feature_set: "Checkout",
    event_priority: "High",
    kingdom: batchId,
    phylum: courseId,
    class: "",
    order: countryCode,
    family: "1001057",
    genus: 0.5,
    species: startDate,
    sub_c_1: numberOfClasses,
    sub_c_2: frequency,
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! Moengage Class_link_click event attributes (1001055)
const classLinkClickAttributes = (
  classId,
  courseId,
  classType,
  classLinkType,
  classDate,
  classTiming,
  isActive,
  timeSinceClassStart,
  classLink = "",
  isZoom,
  cta,
  genus
) => {
  return {
    event_id: "1001055",
    event_type: "Click",
    funnel_stage: "Action",
    event_category: "Class",
    feature_set: "Base",
    event_priority: "High",
    kingdom: classId,
    phylum: courseId,
    class: classType,
    order: classLinkType,
    family: isZoom === "yes" ? "no" : "yes",
    genus: genus || 0,
    species: classDate,
    sub_c_1: classTiming,
    sub_c_2: isActive,
    sub_c_3: timeSinceClassStart,
    sub_c_4: isZoom,
    sub_c_5: classLink,
    sub_c_6: cta,
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! Moengage Class_link_click_inactive event attributes (1001119)
const classLinkClickInactiveAttributes = (
  classId,
  courseId,
  classType,
  classLinkType,
  classDate,
  classTiming,
  isActive,
  timeSinceClassStart,
  classLink = "",
  isZoom,
  cta
) => {
  return {
    event_id: "1001119",
    event_type: "Click",
    funnel_stage: "Action",
    event_category: "Class",
    feature_set: "Base",
    event_priority: "High",
    kingdom: classId,
    phylum: courseId,
    class: classType,
    order: classLinkType,
    family: isZoom === "yes" ? "no" : "yes",
    genus: 1,
    species: classDate,
    sub_c_1: classTiming,
    sub_c_2: isActive,
    sub_c_3: timeSinceClassStart,
    sub_c_4: isZoom,
    sub_c_5: classLink,
    sub_c_6: cta,
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! Moengage Book_Trial_Edit event attributes (1001059)
const bookTrialEditAttributes = (
  stageId,
  stageTitle,
  fieldBeingChanged,
  buttonTitle,
  destinationStage
) => {
  return {
    event_id: "1001059",
    event_type: "Click",
    funnel_stage: "Trial",
    event_category: "Book",
    feature_set: "Trial",
    event_priority: "Medium",
    kingdom: stageId,
    phylum: stageTitle,
    class: fieldBeingChanged,
    order: buttonTitle,
    family: "1001059",
    genus: "",
    species: destinationStage,
    sub_c_1: "",
    sub_c_2: "",
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! Moengage Course_selection_expand event attributes (1001060)
const courseSelectionExpandAttributes = (
  courseId,
  courseTitle,
  coursePosition,
  childAge,
  isReferred
) => {
  return {
    event_id: "1001060",
    event_type: "Click",
    funnel_stage: "Trial",
    event_category: "Book",
    feature_set: "Trial",
    event_priority: "Medium",
    kingdom: courseId,
    phylum: courseTitle,
    class: coursePosition,
    order: childAge,
    family: "1001060",
    genus: 0.5,
    species: "",
    sub_c_1: isReferred,
    sub_c_2: "",
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! ************************************************************************
// ! Continued Onboarding Events
// ! ************************************************************************
// ! Moengage Share_Intent event attributes (1001061)
const shareIntentAttributes = (
  stageId,
  stageTitle,
  activity,
  personalReferralCode,
  buttonTitle = "",
  variant
  // numberOfCoursesSelected = 0
) => {
  return {
    event_id: "1001061",
    event_type: "Click",
    funnel_stage: "Trial",
    event_category: "Book",
    feature_set: "Growth",
    event_priority: "High",
    kingdom: stageId,
    phylum: stageTitle,
    class: activity,
    order: buttonTitle,
    family: "1001061",
    genus: 0.5,
    species: personalReferralCode,
    sub_c_1: variant,
    sub_c_2: "",
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! Moengage Course_outline_click event attributes (1001062)
const courseOutlineClickAttributes = (
  courseId,
  courseTitle,
  childAge,
  destinationPageUrl,
  numberOfCoursesSelected,
  isReferrerCourse = 0
) => {
  return {
    event_id: "1001062",
    event_type: "Click",
    funnel_stage: "Consumption",
    event_category: "Book",
    feature_set: "Growth",
    event_priority: "Medium",
    kingdom: courseId,
    phylum: courseTitle,
    class: "",
    order: childAge,
    family: "1001062",
    genus: 2,
    species: destinationPageUrl,
    sub_c_1: isReferrerCourse,
    sub_c_2: numberOfCoursesSelected,
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! Moengage COB_Stage_click event attributes (1001064)
const COBStageClickAttributes = (
  COBStepId,
  stageTitle,
  buttonTitle,
  nextStageId,
  numberOfCoursesSelected,
  variant = ""
) => {
  return {
    event_id: "1001064",
    event_type: "Click",
    funnel_stage: "Consumption",
    event_category: "Book",
    feature_set: "Growth",
    event_priority: "High",
    kingdom: COBStepId,
    phylum: stageTitle,
    class: "",
    order: buttonTitle,
    family: "1001064",
    genus: 2,
    species: nextStageId,
    sub_c_1: variant,
    sub_c_2: numberOfCoursesSelected,
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! Moengage Add_To_Calendar event attributes (1001066)
const addToCalendarAttributes = (
  courseId,
  slotTime,
  daysFromToday,
  ageOfChild,
  dayOfWeek,
  dateOfClass,
  numberOfCoursesSelected,
  calendarType
) => {
  return {
    event_id: "1001066",
    event_type: "Click",
    funnel_stage: "Consumption",
    event_category: "Book",
    feature_set: "Growth",
    event_priority: "medium",
    kingdom: courseId,
    phylum: slotTime,
    class: daysFromToday,
    order: ageOfChild,
    family: "1001066",
    genus: 2,
    species: dayOfWeek,
    sub_c_1: dateOfClass,
    sub_c_2: numberOfCoursesSelected,
    sub_c_4: calendarType,
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! Moengage Visit_Live event attributes (1001067)
const visitLiveAttributes = (buttonType) => {
  return {
    event_id: "1001067",
    event_type: "Click",
    funnel_stage: "Consumption",
    event_category: "Book",
    feature_set: "Base",
    event_priority: "High",
    kingdom: buttonType,
    phylum: "",
    class: "/live",
    order: "",
    family: "1001067",
    genus: 2,
    species: "",
    sub_c_1: "",
    sub_c_2: "",
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! Moengage Card_Expansion event attributes (1001068)
const cardExpansionAttributes = (
  cardId,
  cardTitle,
  childAge,
  numberOfCoursesSelected
) => {
  return {
    event_id: "1001068",
    event_type: "Click",
    funnel_stage: "Consumption",
    event_category: "Book",
    feature_set: "Base",
    event_priority: "Medium",
    kingdom: cardId,
    phylum: cardTitle,
    class: "",
    order: childAge,
    family: "1001068",
    genus: 2,
    species: "",
    sub_c_1: "",
    sub_c_2: numberOfCoursesSelected,
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! Moengage MSQ_Answer_Selection event attributes (1001069)
const MSQAnswerSelectionAttributes = (
  questionId,
  cardId,
  cardTitle,
  childAge,
  numberOfCoursesSelected
) => {
  return {
    event_id: "1001069",
    event_type: "Click",
    funnel_stage: "Consumption",
    event_category: "Book",
    feature_set: "Base",
    event_priority: "Medium",
    kingdom: questionId,
    phylum: cardId,
    class: cardTitle,
    order: childAge,
    family: "1001069",
    genus: 2,
    species: "",
    sub_c_1: "",
    sub_c_2: numberOfCoursesSelected,
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! Moengage COB_View event attributes (1001070)
const COBViewAttributes = (
  stepId,
  stagetitle,
  nextStageId,
  classnumber = "",
  variant = ""
) => {
  return {
    event_id: "1001070",
    event_type: "Click",
    funnel_stage: "Consumption",
    event_category: "Book",
    feature_set: "Base",
    event_priority: "High",
    kingdom: stepId,
    phylum: stagetitle,
    class: classnumber,
    order: "",
    family: "1001070",
    genus: 2,
    species: nextStageId,
    sub_c_1: variant,
    sub_c_2: "",
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! ***************************************************
// ! New course page events
// ! Moengage Apply_Promo event attributes (1001072)
const applyPromoAttributes = (courseId, numberOfItems, cartTotal, currency) => {
  return {
    event_id: "1001072",
    event_type: "Click",
    funnel_stage: "Conversion",
    event_category: "Cart",
    feature_set: "Checkout",
    event_priority: "Medium",
    kingdom: courseId,
    phylum: numberOfItems,
    class: "",
    order: cartTotal,
    family: "1001072",
    genus: 1,
    species: "",
    sub_c_1: currency,
    sub_c_2: "",
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! Moengage Age_Selection event attributes (1001077)
const ageSelectionAttributes = (age, location) => {
  return {
    event_id: "1001077",
    event_type: "Click",
    funnel_stage: "Consideration",
    event_category: "Browsing",
    feature_set: "Base",
    event_priority: "High",
    kingdom: age,
    phylum: "",
    class: "",
    order: "",
    family: "1001077",
    genus: 2,
    species: "",
    sub_c_1: "",
    sub_c_2: location,
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! Moengage PopUp_View event attributes (1001078)
const popUpViewAttributes = (
  popupType,
  popupTitle,
  defaultValue,
  ctaTitle,
  isBlocker
) => {
  return {
    event_id: "1001078",
    event_type: "Click",
    funnel_stage: "Consideration",
    event_category: "Browsing",
    feature_set: "Base",
    event_priority: "High",
    kingdom: popupType,
    phylum: popupTitle,
    class: defaultValue,
    order: ctaTitle,
    family: "1001078",
    genus: 0,
    species: isBlocker,
    sub_c_1: "",
    sub_c_2: "",
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! Moengage Ribbon_CTA_Click event attributes (1001079)
const ribbonCtaClickAttributes = (ribbonText, ctaTitle) => {
  return {
    event_id: "1001079",
    event_type: "Click",
    funnel_stage: "Consideration",
    event_category: "Browsing",
    feature_set: "Base",
    event_priority: "High",
    kingdom: ribbonText,
    phylum: "",
    class: "",
    order: ctaTitle,
    family: "1001079",
    genus: 0,
    species: "",
    sub_c_1: "",
    sub_c_2: "",
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! Moengage Add_to_Cart_Intent event attributes (1001080)
const addToCartIntentAttributes = (
  courseId,
  courseNames,
  location,
  coursePrice,
  discountedPrice,
  isCombo,
  ABvariant
) => {
  return {
    event_id: "1001080",
    event_type: "Click",
    funnel_stage: "Conversion",
    event_category: "Cart",
    feature_set: "Base",
    event_priority: "High",
    kingdom: courseId,
    phylum: courseNames,
    class: location,
    order: coursePrice,
    family: "1001080",
    genus: 3,
    species: ABvariant,
    sub_c_1: discountedPrice,
    sub_c_2: isCombo,
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! TnC_Click attributes (1001081)
const tncClickAttributes = (linkText, checkboxState, location) => {
  return {
    event_id: "1001081",
    event_type: "Click",
    funnel_stage: "Conversion",
    event_category: "Browsing",
    feature_set: "Base",
    event_priority: "High",
    kingdom: linkText,
    phylum: checkboxState,
    class: location,
    order: "",
    family: "1001081",
    genus: 1,
    species: "",
    sub_c_1: "",
    sub_c_2: "",
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! Get_Started attributes (2001000)
const getStartedAttributes = () => {
  return {
    event_id: "2001000",
    event_type: "Click",
    funnel_stage: "Consumption",
    event_category: "Games",
    feature_set: "Base",
    event_priority: "Medium",
    kingdom: "1",
    phylum: "NA",
    class: "",
    order: "",
    family: "2001000",
    genus: 1,
    species: "",
    sub_c_1: "",
    sub_c_2: "",
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! Unable_To_Join attributes (1001106)
const unableToJoinAttributes = (
  classId,
  courseId,
  classType,
  classDate,
  classTime,
  isActive,
  timeSinceClassStart,
  clicksDone,
  link = ""
) => {
  return {
    event_id: "1001106",
    event_type: "System",
    funnel_stage: "Trial",
    event_category: "Class",
    feature_set: "Base",
    event_priority: "High",
    kingdom: classId,
    phylum: courseId,
    class: classType,
    order: 1,
    family: "1001106",
    genus: 1,
    species: classDate,
    sub_c_1: classTime,
    sub_c_2: isActive,
    sub_c_3: timeSinceClassStart,
    sub_c_4: clicksDone,
    sub_c_5: link,
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! Copy_Link_To_Clipboard attributes (1001107)
const copyLinkToClipboardAttributes = (
  classId,
  courseId,
  classType,
  classDate,
  classTime,
  isActive,
  timeSinceClassStart,
  clicksDone,
  link = ""
) => {
  return {
    event_id: "1001107",
    event_type: "Click",
    funnel_stage: "Trial",
    event_category: "Class",
    feature_set: "Base",
    event_priority: "High",
    kingdom: classId,
    phylum: courseId,
    class: classType,
    order: 1,
    family: "1001106",
    genus: 1,
    species: classDate,
    sub_c_1: classTime,
    sub_c_2: isActive,
    sub_c_3: timeSinceClassStart,
    sub_c_4: clicksDone,
    sub_c_5: link,
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! Pop_Up_Open attributes (1001112)
const popUpOpenAttributes = (
  kingdom, // Pop Up ID (1 = Teacher Calling,2 = Live Class Flow)
  percentage = 0,
  genus = 1,
  modalNumber = 1
) => {
  return {
    event_id: "1001112",
    event_type: "View",
    funnel_stage: "Consumption",
    event_category: "Browsing",
    feature_set: "Base",
    event_priority: "Medium",
    source_page: "/live",
    kingdom: kingdom,
    phylum: [kingdom === 1 ? "Teacher_Calling" : "Live_Class_Flow"],
    class: modalNumber,
    order: percentage,
    family: "",
    genus: genus,
    species: "",
    sub_c_1: "",
    sub_c_2: "",
    sub_c_3: "",
    sub_c_4: "",
    sub_c_5: "",
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! Pop_Up_Close attributes (1001113)
const popUpCloseAttributes = (
  kingdom, // Pop Up ID (1 = Teacher Calling,2 = Live Class Flow)
  percentage = 0,
  modalNumber = 1
) => {
  return {
    event_id: "1001113",
    event_type: "Click",
    funnel_stage: "Consumption",
    event_category: "Browsing",
    feature_set: "Base",
    event_priority: "Low",
    source_page: "/live",
    kingdom: kingdom,
    phylum: [kingdom === 1 ? "Teacher_Calling" : "Live_Class_Flow"],
    class: modalNumber,
    order: percentage,
    family: "",
    genus: 1,
    species: "",
    sub_c_1: "",
    sub_c_2: "",
    sub_c_3: "",
    sub_c_4: "",
    sub_c_5: "",
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! Pop_Up_Click attributes (1001028)
const popUpClickAttributes = (
  buttonId, //Button ID ( 1 = Primary, 2 = secondary, 3 = Dismiss, 4 = Any other,5 = Download from PlayStore)
  buttonTitle,
  heading,
  destinationHead,
  destinationId = 1
) => {
  return {
    event_id: "1001028",
    event_type: "Click",
    funnel_stage: "Consideration",
    event_category: "Browsing",
    feature_set: "Base",
    event_priority: "Low",
    source_page: "/live",
    kingdom: buttonId,
    phylum: buttonTitle,
    class: destinationHead,
    order: heading,
    family: "1001028",
    genus: "",
    species: destinationId,
    sub_c_1: "",
    sub_c_2: "",
    sub_c_3: "",
    sub_c_4: "",
    sub_c_5: "",
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! Pop_Up_Enter attributes (1001125)
const popUpEnterAttributes = (
  kingdom, // Pop Up ID (1 = Teacher Calling,2 = Live Class Flow)
  buttonTitle,
  destinationId
) => {
  return {
    event_id: "1001125",
    event_type: "Platform",
    funnel_stage: "Consumption",
    event_category: "Browsing",
    feature_set: "Base",
    event_priority: "Low",
    source_page: "/live",
    kingdom: kingdom,
    phylum: buttonTitle,
    class: destinationId,
    order: "",
    family: "1001125",
    genus: "",
    species: destinationId,
    sub_c_1: "",
    sub_c_2: "",
    sub_c_3: "",
    sub_c_4: "",
    sub_c_5: "",
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! Snack_Bar_Click attributes (1001127)
const snackBarClickAttributes = (
  kingdom, // Snack Bar ID (1 = Teacher Calling,2 = Live Class Pop Up Flow, 3= Join Now)
  snackBarTitle,
  snackbarStatus,
  percentage = 0
) => {
  return {
    event_id: "1001127",
    event_type: "Click",
    funnel_stage: "Consumption",
    event_category: "Browsing",
    feature_set: "Base",
    event_priority: "Medium",
    source_page: "/live",
    kingdom: kingdom,
    phylum: snackBarTitle,
    class: snackbarStatus,
    order: "",
    family: "1001127",
    genus: "",
    species: percentage,
    sub_c_1: "",
    sub_c_2: "",
    sub_c_3: "",
    sub_c_4: "",
    sub_c_5: "",
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! Slot_Nav_Bar_Click attributes (1001124)
const slotNavBarClickAttributes = (
  title,
  titleNum, // order from left
  childAge,
  isReferrerSlot, // yes/no
  referredId,
  recommended // yes/no
) => {
  return {
    event_id: "1001124",
    event_type: "Click",
    funnel_stage: "Trial",
    event_category: "Book",
    feature_set: "Trial",
    event_priority: "Medium",
    source_page: "/book-a-trial",
    kingdom: title,
    phylum: titleNum,
    class: "",
    order: childAge,
    family: "1001124",
    genus: "",
    species: "",
    sub_c_1: isReferrerSlot,
    sub_c_2: "",
    sub_c_3: referredId,
    sub_c_4: recommended,
    sub_c_5: "",
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};
// ! Class_Page_View attributes (1001128)
const classPageViewAttributes = (
  source,
  liveClassId,
  timeRem,
  courseId = ""
) => {
  return {
    event_id: "1001128",
    event_type: "View",
    funnel_stage: "Consideration",
    event_category: "Browsing",
    feature_set: "Base",
    event_priority: "High",
    source_page: source,
    kingdom: courseId,
    phylum: liveClassId,
    class: timeRem,
    order: "",
    family: "",
    genus: "",
    species: "",
    sub_c_1: "",
    sub_c_2: "",
    sub_c_3: "",
    sub_c_4: "",
    sub_c_5: "",
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};

//!Certificate_Download attributes (1001133)

const certificateDownloadAttributes = (
  courseId,
  courseTitle,
  batchType,
  childAge,
  certificateSwipeIndex,
  isReferrarCourse, // yes/no
  referrerId
) => {
  return {
    event_id: "1001133",
    event_type: "Click",
    funnel_stage: "Post Trial",
    event_category: "Book",
    feature_set: "Trial",
    event_priority: "Medium",
    source_page: "/live",
    kingdom: courseId,
    phylum: courseTitle,
    class: batchType,
    order: childAge,
    family: "",
    genus: "",

    species: "",
    sub_c_1: isReferrarCourse,
    sub_c_2: certificateSwipeIndex,
    sub_c_3: referrerId,

    sub_c_4: "",
    sub_c_5: "",
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};

// ! action
const gameActivityAttributes = (
  questionId,
  gameUnit,
  answer,
  timerFlag,
  points,
  questionNumber,
  gameTitle,
  childAge,
  isCorrect
) => {
  return {
    event_id: "3001012",
    event_type: "Click",
    funnel_stage: "Consumption",
    event_category: "Games",
    feature_set: "Base",
    event_priority: "Medium",
    source_page: "5",
    kingdom: questionId,
    phylum: gameUnit,
    class: gameTitle,
    order: answer,
    family: timerFlag,
    genus: points,
    species: "click",
    sub_c_1: "0",
    sub_c_2: questionNumber,
    sub_c_3: "",
    sub_c_4: "",
    sub_c_5: isCorrect,
    sub_c_6: childAge,
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};

// ! Teacher_Confirmation attributes (1001141)
const teacherConfirmationAttributes = (
  action,
  simultaneousAction,
  courseIdentifier,
  batchId,
  dateAndTime,
  teacherCutOff,
  timeZone,
  cofirmAll,
  teacherName,
  slotCutOff,
  facultyIdentifier
) => {
  return {
    event_id: "1001141",
    event_type: "Platform",
    funnel_stage: "Action",
    event_category: "Dashboard",
    feature_set: "Teacher",
    event_priority: "High",
    source_page: "/teacher-dashboard",
    kingdom: action,
    phylum: simultaneousAction,
    class: courseIdentifier,
    order: batchId,
    family: dateAndTime,
    genus: teacherCutOff,
    species: timeZone,
    sub_c_1: cofirmAll,
    sub_c_2: teacherName,
    sub_c_3: slotCutOff,
    sub_c_4: facultyIdentifier,
    sub_c_5: "",
    app_version: "0.1.13",
    a_b_variant: "a",
  };
};

// ! TimeZone_Change attributes (1001142)
const timeZoneChangeAttributes = (newTimeZone, previousTimeZone) => {
  return {
    event_id: "1001142",
    event_type: "Platform",
    funnel_stage: "Action",
    event_category: "Dashboard",
    feature_set: "Teacher",
    event_priority: "Medium",
    source_page: "/teacher-dashboard",
    kingdom: newTimeZone,
    phylum: previousTimeZone,
    class: "",
    order: "",
    family: "",
    genus: "",
    species: "",
    sub_c_1: "",
    sub_c_2: "",
    sub_c_3: "",
    sub_c_4: "",
    sub_c_5: "",
    app_version: "0.1.13",
    a_b_variant: "",
  };
};

const schoolRepSpellingBee = (id) => {
  return {
    event_id: "1001143",
    event_type: "Platform",
    funnel_stage: "Action",
    event_category: "Search",
    feature_set: "Search",
    event_priority: "High",
    source_page: "/spelling-bee/l1",
    kingdom: id,
    phylum: "",
    class: "Success",
    order: "",
    family: "",
    genus: "",
    species: "",
    sub_c_1: "",
    sub_c_2: "",
    sub_c_3: id,
    sub_c_4: "",
    sub_c_5: "",
    app_version: "Web only",
    a_b_variant: "",
  };
};

export {
  pageVisitAttributes,
  ageFilterAttributes,
  addToCartAttributes,
  clickToHomepageAttributes,
  mainMenuClickAttributes,
  categoryFilterAttributes,
  searchExecuteAttributes,
  checkoutAttributes,
  bookTrialViewAttributes,
  footertAttributes,
  bookTrialClickAttributes,
  bookTrialFieldFilledAttributes,
  bookTrialSubmitAttributes,
  bookTrialSuccessAttributes,
  invokePaymentAttributes,
  paymentStatusAttributes,
  requestCallbackAttributes,
  connectWithUsAttributes,
  joinClassAttributes,
  leaveClassAttributes,
  buttonClickAttributes,
  cardClickAttributes,
  addCouponAttributes,
  addCouponSuccessAttributes,
  courseSelectionAttributes,
  slotSelectionAttributes,
  requestCustomSlotAttributes,
  enterOTPAttributes,
  OTPStatusAttributes,
  requestOTPAttributes,
  resendOTPAttributes,
  dismissOTPAttributes,
  loginAttributes,
  loginFieldFillAttributes,
  loginFieldFilledAttributes,
  selectBatchModalAttributes,
  BuyNowAttributes,
  classLinkClickAttributes,
  bookTrialEditAttributes,
  courseSelectionExpandAttributes,
  shareIntentAttributes,
  courseOutlineClickAttributes,
  COBStageClickAttributes,
  addToCalendarAttributes,
  visitLiveAttributes,
  cardExpansionAttributes,
  MSQAnswerSelectionAttributes,
  COBViewAttributes,
  applyPromoAttributes,
  ageSelectionAttributes,
  popUpViewAttributes,
  ribbonCtaClickAttributes,
  addToCartIntentAttributes,
  tncClickAttributes,
  loginToBuyAttributes,
  rescheduleAttributes,
  getStartedAttributes,
  unableToJoinAttributes,
  copyLinkToClipboardAttributes,
  classLinkClickInactiveAttributes,
  popUpClickAttributes,
  popUpOpenAttributes,
  popUpCloseAttributes,
  popUpEnterAttributes,
  snackBarClickAttributes,
  slotNavBarClickAttributes,
  classPageViewAttributes,
  certificateDownloadAttributes,
  gameActivityAttributes,
  teacherConfirmationAttributes,
  timeZoneChangeAttributes,
  schoolRepSpellingBee,
};
