import Bowser from "bowser";
import UAParser from "ua-parser-js";

const parser = new UAParser();
let deviceInfo = parser.getDevice();

let userAgent,
  uniqueDeviceId,
  location,
  ipAddress,
  zipcode,
  uniqueUserId = "",
  initialAttributes;
if (typeof window === "object") {
  // !  getting the user agent details
  userAgent = Bowser.parse(window.navigator.userAgent);
  // ! creating device id
  var navigator_info = window.navigator;
  var screen_info = window.screen;
  let uid = "";
  uid += navigator_info.userAgent.replace(/\D+/g, "");
  uid += navigator_info.plugins.length;
  uid += screen_info.availHeight || "";
  uid += screen_info.availWidth || "";
  uid += screen_info.pixelDepth || "";
  uniqueDeviceId = uid;
  // ! Getting the location string
  location = () => {
    if (window?.sessionStorage?.ipapi_response) {
      let data = JSON.parse(window?.sessionStorage?.ipapi_response);
      return `${data.city}, ${data.region}, ${data.country}`;
    } else {
      return "";
    }
  };
  // ! getting the ip
  ipAddress = () => {
    if (window?.sessionStorage?.ipapi_response) {
      return JSON.parse(window.sessionStorage.ipapi_response).ip;
    } else {
      return "NO IP FOUND";
    }
  };
  // ! getting the zipcode
  zipcode = () => {
    if (window?.sessionStorage?.ipapi_response) {
      return JSON.parse(window.sessionStorage.ipapi_response).postal;
    } else {
      return "NO ZIPCODE FOUND";
    }
  };
  // ! auth_userid for events
  if (window.localStorage["persist:root"])
    uniqueUserId =
      JSON.parse(JSON.parse(window.localStorage["persist:root"]).auth)
        .userDetails.id || "";
  // ! Initial attributes for all the requests
  initialAttributes = {
    event_id: "",
    event_type: "",
    funnel_stage: "",
    event_category: "",
    feature_set: "",
    event_priority: "",
    domain: window.location.origin,
    source_page: window.location.pathname,
    kingdom: "",
    phylum: "",
    class: "",
    order: "",
    family: "",
    genus: "",
    species: "",
    sub_c_1: "",
    sub_c_2: "",
    sub_c_3: "",
    server_timestamp: new Date().toUTCString(),
    device_timestamp: new Date().toISOString(),
    app_version: "",
    a_b_variant: "",
    visitor_uuid: window.localStorage.visitor_uuid,
    auth_userid: uniqueUserId,
    device_id: uniqueDeviceId,
    device_identifier: uniqueDeviceId,
    //   device_timezone: new Date().toLocaleString(),
    model_number: deviceInfo.model || userAgent.os.version,
    device_manufacturing_company: deviceInfo.vendor || userAgent.os.name,
    location: location(),
    network: "",
    ip_address: ipAddress(),
    os: userAgent.os.name,
    user_agent: userAgent.source,
    browser: userAgent.browser.name,
    platform: userAgent.platform.type,
    zipcode: zipcode(),
  };
}

const stoppingEventsName = new Set([
  "Page_View",
  "Click_to_Home_Page",
  "Main_Menu_Click",
  "Banner_Click",
  "Button_Click",
  "Age_filter",
  "Category_Filter",
  "Card_Click",
  "Pop_Up_Click",
  "Chat_start",
  "Chat_end",
  "Social_Clicks",
  "Scroll_options",
  "Footer_Event",
  "Header_Event",
  "Search Click",
  "Search_Invoke",
  "Search_Execute",
  "Cart_click",
  "Checkout",
  "Change_quantity",
  "Book_Trial_View",
  "Book_Trial_Click",
  "Book_Trial_Field_Fill",
  "Book_Trial_Field_Filled",
  "Course_Selection",
  "Slot_Selection",
  "Request Custom Slot",
  "Book_Trial_Submit",
  "Checkbox_clicked",
  "Add_to_Cart",
  "Add_Coupon",
  "Add_Coupon_Success",
  "Cart_Field_Fill",
  "Cart_Field_Filled",
  "Expand Question",
  "View Content",
  "Request_Callback",
  "Connect_With_Us",
  "Enter_Otp",
  "OTP_Status",
  "Request_Otp",
  "Dismiss_Otp",
  "Resend_Otp",
  "Login",
  "Login_Field_Fill",
  "Login_Field_Filled",
  "Class_status_click",
  "Select_Batch_Modal",
  "pop_up_on_error",
  "Book_Trial_Edit",
  "Course_Selection_Expand",
  "Course_Outline_Click",
  "COB_Stage_Click",
  "COB_Stage_Secondary_click",
  "Add_To_Calendar",
  "Visit_Live",
  "Card_Expansion",
  "MSQ_Answer_Selection",
  "Answer_action",
  "COB_View",
  "Inactive_Button_Click",
  "Apply_Promo",
  "Gift_Course",
  "Video_Start",
  "Video_Stop",
  "Add_to_Cart_Combo",
  "Age_Selection",
  "PopUp_View",
  "Ribbon_CTA_Click",
  "Add_To_Cart_Intent",
  "TnC_Click",
  "Dashboard_Nav_click",
  "Dashboard_Drawer_Click",
  "Dashboard_Class_Click",
  "Assignment_Click",
  "Assignment_View",
  "Assignment_Upload_Click",
  "Dashboard_Week_Click",
  "Profile_Pic_Click",
  "Profile_Bio_Click",
  "My_Studio_Click",
  "Dashboard_Banner_Click",
  "Dashboard_Right_Column_Click",
  "Profile_Pic_Selection",
  "Enrolled_Show_Progress",
  "Enrolled_Session_Click",
  "Enrolled_Session_Image_Click",
  "Assignment_Upload_Attempt",
  "Assignment_Upload_Submit",
  "Express_Interest",
  "Expand_Section",
  "Carousel_Clicks",
  "Buy_now",
  "Login_to_buy",
  "Reschedule_Class",
  "Unable_To_Join",
  "Copy_Link_To_Clipboard",
  "Parent_Available",
  "Teacher_Calling",
  "Teacher_Calling_Error",
  "Pop_Up_Open",
  "Pop_Up_Close",
  "Video_Like_View_App",
  "Video_Like_Click",
  "Feeback_Field_Filled",
  "Feedback_Click",
  "Feeback_Submit",
  "Waiting_room_joined",
  "Waiting_room_left",
  "Zoom_Sdk_Back_Button",
  "class_attended",
  "Slot_Nav_Bar_Click",
  "Pop_Up_Enter",
  "Snack_Bar_View",
  "Snack_Bar_Click",
  "Class_Page_View",
  "landing_incorrect",
  "Invalid_Coupon",
  "Referred_Claim",
  "Certifcate_Download",
  "Referral_Field_Filled",
  "Account_Select_Dashboard",
  "FTUE_View",
  "FTUE_Click",
  "Auto_Scroll_Click",
  "Download_Button_Click",
  "Teacher_Confirmation",
  "TimeZone_Change",
]);

const moengageEvent = async (eventName, eventAttributes = {}) => {
  // ! Setting the auth_userid

  if (stoppingEventsName.has(eventName)) return;

  let uniqueUserId = "",
    schoolId = "",
    referrer_code = "";
  if (window.localStorage["persist:root"]) {
    let persistedRoot = JSON.parse(window.localStorage["persist:root"]);
    uniqueUserId = JSON.parse(persistedRoot.auth).userDetails.id || "";
    schoolId = JSON.parse(persistedRoot.utility).utmObject?.utm_custom_id || "";
    referrer_code =
      JSON.parse(persistedRoot.utility).referrerDetails?.referrer_code || "";
  }
  // ! adding auth_userid
  initialAttributes.auth_userid = uniqueUserId;
  initialAttributes.source_page = window.location.pathname;
  initialAttributes.sub_c_3 = schoolId;
  // ! add referrer_code for required events
  let bookingRelatedEventIds =
    "1001001,1001014,1001015,1001016,1001017,1001039,1001040,1001041,1001026,1001037,1001059,1001060,1001061,1001062,1001064,1001066,1001067,1001062,1001068,1001069,1001070,1001071,1001080";
  if (
    referrer_code &&
    bookingRelatedEventIds.includes(eventAttributes.event_id)
  ) {
    initialAttributes.sub_c_3 = referrer_code;
  }
  // ! mixpanel trcking
  // window?.mixpanel?.track(eventName, {
  //   ...initialAttributes,
  //   ...eventAttributes,
  // });
  window.__posthog.capture(eventName, {
    ...initialAttributes,
    ...eventAttributes,
  });
};

export default moengageEvent;
// initialAttributes = {
//   event_id: "1001045",
//   event_type: "Click",
// funnel_stage: "Consumption",
// event_category: "Class",
// feature_set: "Base",
// event_priority: "High",
//   domain: "sparkstudio.co",
//   source_page: "/booking",
//   kingdom: classId,
//   phylum: courseId,
//   class: classType (trial/paid),
//   order: teacherPresent (0/1),
//   family: "1001045",
//   genus: 4,
//   species: classDate,
//   sub_c_1: classTiming,
//   sub_c_2: sessionNo,
//   auth_userid: 1234,
//   server_timestamp: "timestamp here (datestring)",
// //! After this just push as it is
//   sub_c_3: "",
//   sub_c_4: "",
//   sub_c_5: 1,
//   device_timestamp: "timestamp here",
//   app_version: "1.0.12 currently. Doesnt really matter",
//   a_b_variant: "",
//   visitor_uuid: "",
//   device_id: "",
//   device_identifier: "",
//   //   device_timezone: new Date().toLocaleString(),
//   model_number: "",
//   device_manufacturing_company: "",
//   location: "",
//   network: "",
//   ip_address: "",
//   os: "",
//   user_agent: "",
//   browser: "",
//   platform: "",
//   zipcode: "",
// };
